




























































































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { validationMixin } from 'vuelidate';
import {
  helpers, minLength, required, sameAs,
} from 'vuelidate/lib/validators';
import {
  BContainer, BForm, BFormInput, BIconInfoCircle,
} from 'bootstrap-vue';
import { Getter, namespace } from 'vuex-class';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import ButtonComponent from '@/components/ButtonComponent.vue';
import AlertComponent from '@/components/AlertComponent.vue';
import AlertType from '@/utils/enums/AlertType';
import InputText from '@/components/InputText.vue';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import CompanyUserRoleStatusType from '@/utils/enums/CompanyUserRoleStatusType';
import CommunityUser from '@/models/graphql/CommunityUser';
import MessagePage from '@/views/guest/MessagePage.vue';
import IllustrationType from '@/utils/enums/IllustrationType';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import ActionType from '@/utils/enums/ActionType';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import { mixins } from 'vue-class-component';
import { EMAIL } from '@/utils/constants/Regex';

const authenticationStore = namespace('AuthenticationStore');
const communityUserStore = namespace('CommunityUserStore');
const emailValidation = helpers.regex('email', EMAIL);

@Component({
  components: {
    AvatarSoloWidget,
    InputText,
    ButtonComponent,
    BContainer,
    BForm,
    BFormInput,
    BIconInfoCircle,
    AlertComponent,
    MessagePage,
  },
  mixins: [validationMixin],
  validations: {
    form: {
      email: {
        required,
        minLength: minLength(1),
        emailValidation,
      },
      firstName: {
        required,
        minLength: minLength(1),
      },
      lastName: {
        required,
        minLength: minLength(1),
      },
      termsOfService: {
        required,
        sameAs: sameAs(() => true),
      },
      privacyPolicy: {
        required,
        sameAs: sameAs(() => true),
      },
    },
  },
  data(): object {
    return {
      firstNameError: false,
      emailError: false,
      lastNameError: false,
    };
  },
})
export default class CreateAccountPage extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  @Prop({ required: false, default: '' })
  errorMessage!: string;

  @Getter
  protected featureByKey!: (key: FeatureKeys) => CommunityFeature;

  @communityUserStore.Getter
  private fetchCommunityUsers!: CommunityUser[];

  @authenticationStore.Getter
  private isAuthenticated!: boolean;

  @communityUserStore.Action(ActionType.FILTER)
  private filterCommunityUsers!: (payload: { filter: { email: string } }) => void;

  @communityUserStore.Action
  private createAccount!: (
    entity: { email: string; firstName: string; lastName: string; state: string }
  ) => Promise<void>;

  @authenticationStore.State
  private authErrors!: string[];

  private form = {
    email: null,
    firstName: null,
    lastName: null,
    termsOfService: false,
    privacyPolicy: false,
  };

  private termsOfService = false;

  private privacyPolicy = false;

  private alertType = AlertType;

  private FeatureKeys = FeatureKeys;

  private IllustrationType = IllustrationType;

  private FileResourceHelper = FileResourceHelper;

  private emailExist = false;

  private createAccountBtnLoading = false;

  private messagePageType = '';

  private lowerCaseEmail = '';

  private get logoPath(): string {
    if (this.community?.logoFileResource?.path) {
      return FileResourceHelper
        .getImagePathWithSize(FileResourceHelper.getFullPath(this.community?.logoFileResource), 'w96');
    }
    return '';
  }

  created(): void {
    this.setDataConfig();
  }

  mounted(): void {
    if (this.isAuthenticated || !this.featureByKey(FeatureKeys.USER_SIGNUP).enabled) {
      this.$router.replace({ path: '/' });
    }
  }

  @Watch('fetchCommunityUsers')
  createCommunityUser(): void {
    if (this.fetchCommunityUsers.length > 0) {
      this.emailExist = true;
    } else if (this.form.email && this.form.firstName && this.form.lastName) {
      this.createAccountBtnLoading = true;
      this.createAccount({
        email: this.lowerCaseEmail ?? '',
        firstName: this.form.firstName ?? '',
        lastName: this.form.lastName ?? '',
        state: CompanyUserRoleStatusType.TO_BE_INVITED,
      }).then(() => {
        this.messagePageType = 'create-account-success';
      }).catch((error) => {
        this.errorMessage = error;
      });
    }
  }

  onSubmit(): void {
    if (this.form.email && this.form.firstName && this.form.lastName) {
      this.filterCommunityUsers({
        filter: { email: this.lowerCaseEmail ?? '' },
      });
    }
  }

  private disabledSubmit(): boolean {
    return this.$v.$invalid || this.createAccountBtnLoading;
  }

  private onBlur(fieldType: string): void {
    if (this.$v) {
      switch (fieldType) {
        case 'email':
          this.lowerCaseEmail = (this.form.email as unknown as string).toLowerCase();
          this.$data.emailError = this.$v.form.email ? this.$v.form.email.$invalid : false;
          break;
        case 'firstName':
          // eslint-disable-next-line max-len
          this.$data.firstNameError = this.$v.form.firstName ? this.$v.form.firstName.$invalid : false;
          break;
        case 'lastName':
          this.$data.lastNameError = this.$v.form.lastName ? this.$v.form.lastName.$invalid : false;
          break;
        default:
          break;
      }
    }
  }
}
